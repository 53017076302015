import moment from 'moment';

const formatAccountingDate = (transaction) => {
  const dateString = moment.utc(transaction.accounting_date).format('MMM DD, YYYY');
  // payment status: Paid, Upcoming, Receivable
  if (transaction.payment_status.toLowerCase() === 'paid') {
    return dateString;
  } 
  
  return `--`;
};

export default formatAccountingDate;
