import { createContext } from 'react';

const LeaseAbstractionContext = createContext({
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  individualModalFieldChangeHandler: () => {},
  fieldErrorMap: {},
  validateLeaseAbstractionFields: () => {},
  goToPage: () => {},
  isMagnified: false,
});

export default LeaseAbstractionContext;
