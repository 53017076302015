import React, { useContext } from 'react';

import FileUploadAccordian from '../../file-upload-accordian';
import countries from '../../../../data/countries.json';
import states from '../../../../data/states.json';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const LandlordInformation = () => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
  } = useContext(LeaseAbstractionContext);
  return (
    <FileUploadAccordian accordianTitle="Landlord Information">
      <LeaseAbstractionField
        key="landlordCompanyName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordCompanyName,
          label: 'Company Name',
          errorMessage: fieldErrorMap.landlordCompanyName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordCompanyName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordCompanyName.trackingID,
            stateVariable: 'landlordCompanyName',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordCountryName"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.landlordCountryName,
          label: 'Country',
          errorMessage: fieldErrorMap.landlordCountryName,
          options: countries,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler(
            'multipleFieldNames',
            {
              landlordCountryName: {
                ...modalFieldState.landlordCountryName,
                value: event.value,
              },
              landlordStateName: {
                ...modalFieldState.landlordStateName,
                value: '',
              },
              filteredStatesForLandlord: {
                value: getFilteredStates(event.value),
              },
            },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordCountryName.trackingID,
            stateVariable: 'landlordCountryName',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordAddressLine1"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordAddressLine1,
          label: 'Address Line 1',
          errorMessage: fieldErrorMap.landlordAddressLine1,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordAddressLine1', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordAddressLine1.trackingID,
            stateVariable: 'landlordAddressLine1',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordAddressLine2"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordAddressLine2,
          label: 'Address Line 2',
          errorMessage: fieldErrorMap.landlordAddressLine2,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordAddressLine2', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordAddressLine2.trackingID,
            stateVariable: 'landlordAddressLine2',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordCity"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordCity,
          label: 'City',
          errorMessage: fieldErrorMap.landlordCity,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordCity', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordCity.trackingID,
            stateVariable: 'landlordCity',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordStateName"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.landlordStateName,
          label: 'State',
          errorMessage: fieldErrorMap.landlordStateName,
          options: modalFieldState.filteredStatesForLandlord.value,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordStateName', { value: event.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordStateName.trackingID,
            stateVariable: 'landlordStateName',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordZip"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordZip,
          label: 'Zip',
          errorMessage: fieldErrorMap.landlordZip,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordZip', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordZip.trackingID,
            stateVariable: 'landlordZip',
          });
        }}
      />
      <p className="file-modal-sub-heading">Point of Contact</p>
      <LeaseAbstractionField
        key="landlordPOCFirstName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordPOCFirstName,
          label: 'First Name',
          errorMessage: fieldErrorMap.landlordPOCFirstName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordPOCFirstName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordPOCFirstName.trackingID,
            stateVariable: 'landlordPOCFirstName',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordPOCLastName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordPOCLastName,
          label: 'Last Name',
          errorMessage: fieldErrorMap.landlordPOCLastName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordPOCLastName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordPOCLastName.trackingID,
            stateVariable: 'landlordPOCLastName',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordPOCPhone"
        fieldType={LeaseAbstractionFieldType.PHONE}
        fieldData={{
          data: modalFieldState.landlordPOCPhone,
          label: 'Phone',
          errorMessage: fieldErrorMap.landlordPOCPhone,
        }}
        fieldChangeHandler={(value, isValid) => {
          modalFieldChangeHandler(
            'multipleFieldNames',
            {
              landlordPOCPhone: {
                ...modalFieldState.landlordPOCPhone,
                value,
              },
              landlordPOCPhoneValid: isValid,
            },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordPOCPhone.trackingID,
            stateVariable: 'landlordPOCPhone',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordPOCEmail"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordPOCEmail,
          label: 'E-Mail Address',
          errorMessage: fieldErrorMap.landlordPOCEmail,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordPOCEmail', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordPOCEmail.trackingID,
            stateVariable: 'landlordPOCEmail',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordPOCJobTitle"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordPOCJobTitle,
          label: 'Job Title',
          errorMessage: fieldErrorMap.landlordPOCJobTitle,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordPOCJobTitle', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'landlord_seller_info',
            child_key: modalFieldState.landlordPOCJobTitle.trackingID,
            stateVariable: 'landlordPOCJobTitle',
          });
        }}
      />
    </FileUploadAccordian>
  );
};

LandlordInformation.propTypes = {
};

LandlordInformation.defaultProps = {
};

export default LandlordInformation;
