import React, { useContext } from 'react';

import AddItem from '../../../../nucleus/add-item/add-item';
import CustomConsiderationField from './components/customConsiderationsField';
import FileUploadAccordian from '../../file-upload-accordian';
import { getRandomId } from '../../../../utils/validation-utils';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const Considerations = () => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
  } = useContext(LeaseAbstractionContext);
  return (
    <FileUploadAccordian accordianTitle="Considerations">
      <p className="file-modal-sub-heading">Consideration Breakdown</p>
      <>
        {modalFieldState.customConsideration.value.map(customConsideration => (
          <CustomConsiderationField
            currentCustomConsideration={customConsideration}
            key={customConsideration.id}
          />
          ))}
        <div className="file-modal-add-button">
          <AddItem
            label="Add Rent"
            onClick={() => {
              const newCustomConsiderationValue = [
                ...modalFieldState.customConsideration.value,
              ];
              newCustomConsiderationValue.push({
                id: getRandomId(),
                consideration_type: 'Monthly Rent',
                total_units: '',
                amount: '',
                consideration_amount: '',
                commission_percent: '',
                commission_dollars: '',
                commission_amount: '',
              });
              modalFieldChangeHandler('customConsideration', {
                value: newCustomConsiderationValue,
              });
              individualModalFieldChangeHandler({
                child_value: newCustomConsiderationValue,
                parent_key: 'consideration_info',
                child_key: modalFieldState.customConsideration.trackingID,
                stateVariable: 'customConsideration',
              });
            }}
          />
        </div>
      </>
    </FileUploadAccordian>
  );
};

Considerations.propTypes = {
};

Considerations.defaultProps = {
};

export default Considerations;
