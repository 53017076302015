import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import Flyout from '../flyout/flyout';
import Option from '../option/option';
import NDButton from '../button/button';

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: this.props.defaultOption,
      showFlyout: false,
      highlightedIndex: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    // The onClear prop is a special use case when a parent component needs to reset the state
    // of the Select component to the default
    if (this.state.selectedOption !== nextProps.defaultOption && this.props.onClear) {
      this.setState({ selectedOption: nextProps.defaultOption });
    }
    return true;
  }

  onKeyDown = (e) => {
    const { highlightedIndex } = this.state;
    switch (e.keyCode) {
      case 38:
        e.preventDefault();
        this.onUpArrowPress();
        break;
      case 40:
        e.preventDefault();
        this.onDownArrowPress();
        break;
      case 9:
        if (highlightedIndex !== null) {
          this.onSelectionPress();
        } else {
          this.closeFlyout();
        }
        break;
      case 13:
        this.onSelectionPress();
        break;
      default:
        break;
    }
  };

  onSelectionPress = () => {
    const { highlightedIndex } = this.state;
    const { options } = this.props;
    if (highlightedIndex !== null) {
      this.handleOptionClick(options[highlightedIndex]);
    }
  }

  onUpArrowPress = () => {
    const { highlightedIndex } = this.state;
    const { options } = this.props;
    let newIndex = highlightedIndex;
    if (highlightedIndex && highlightedIndex !== 0) {
      newIndex = highlightedIndex - 1;
      this.setState({ highlightedIndex: newIndex });
    } else {
      newIndex = options.length - 1;
      this.setState({ highlightedIndex: newIndex });
    }
  }

  onDownArrowPress = () => {
    const { highlightedIndex } = this.state;
    const { options } = this.props;
    let newIndex = highlightedIndex;
    const length = options.length - 1;
    if (highlightedIndex !== null && highlightedIndex < length) {
      newIndex = highlightedIndex + 1;
      this.setState({ highlightedIndex: newIndex });
    } else {
      newIndex = 0;
      this.setState({ highlightedIndex: newIndex });
    }
  }

  handleOptionClick = (option) => {
    if (option !== this.state.selectedOption) {
      this.setState({ selectedOption: option });
      this.props.onChange(option);
    }
    this.closeFlyout();
  }

  openFlyout = () => {
    this.setState({ showFlyout: true });
  }

  closeFlyout = () => {
    this.setState({
      showFlyout: false,
      highlightedIndex: null,
    });
  }

  displayErrorState = () => {
    const {
      errorMessage,
    } = this.props;
    return (
      errorMessage ? <p className="error">{errorMessage}</p> : ''
    );
  }

  render() {
    const {
      defaultOption,
      dropdownIcon,
      options,
      errorMessage,
      className,
      disabled,
      showDefault,
      isMagnified,
    } = this.props;

    const {
      selectedOption,
      highlightedIndex,
      showFlyout,
    } = this.state;

    const renderOptions = options.map((option, index) => {
      const isActive = option.value === selectedOption.value;
      return (
        <Option
          isMagnified={isMagnified}
          isActive={isActive}
          key={uniqueId()}
          option={option}
          onClick={this.handleOptionClick}
          isHighlighted={index === highlightedIndex}
        />
      );
    });

    // the currentValue can be 3 things:
    // * the currently selected value
    // * defaultOption.display_name (if the defaultOption is an {})
    // * or defaultOption as a string. see propTypes for addtional info
    const currentValue = showDefault ? defaultOption : selectedOption.display_name || defaultOption.display_name || defaultOption;

    return (
      <div className={`${className} nd-select-component`}>
        <NDButton
          isMagnified={isMagnified}
          className="select-button"
          disabled={disabled}
          onMouseDown={this.openFlyout}
          onKeyDown={this.onKeyDown}
          onFocus={this.openFlyout}
          dataE2e={this.props.dataE2e}
          variant="action"
          icon={dropdownIcon}
          iconPosition="right"
          width="full"
          invalid={!!errorMessage.length}
        >
          {currentValue || <span className="placeholder">Select</span>}
        </NDButton>
        <Flyout
          showFlyout={showFlyout}
          closeFlyout={this.closeFlyout}
        >
          <div className="select">
            <ul className="list-handler">
              {renderOptions}
            </ul>
          </div>
        </Flyout>
        {this.displayErrorState()}
      </div>
    );
  }
}

Select.propTypes = {
  className: PropTypes.string,
  onClear: PropTypes.bool,
  // in some cases, the defaultOption needs to be a string
  // because it is not an option that is a part of the
  // options list. ex: a snooze dropdown has a defaultOption
  // of "Remind Me" which is just a string because it is not
  // considered a valid Option for its use case
  defaultOption: PropTypes.oneOfType([
    PropTypes.shape({
      display_name: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  dropdownIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  errorMessage: PropTypes.string,
  dataE2e: PropTypes.string,
  disabled: PropTypes.bool,
  showDefault: PropTypes.bool,
  isMagnified: PropTypes.bool,
};

Select.defaultProps = {
  className: '',
  defaultOption: '',
  dropdownIcon: 'chevron-down',
  onClear: false,
  errorMessage: '',
  dataE2e: '',
  disabled: false,
  showDefault: false,
  isMagnified: false,
};

export default Select;

