import moment from 'moment';

const formatPaymentDate = (transaction) => {
  const date = transaction.paid_date || transaction.accounting_date || transaction.installment_date;
  const dateString = moment.utc(date).format('MMM DD, YYYY');
  const installmentPaidDate = transaction.paid_date;
  const installmentPaidDateString = moment.utc(installmentPaidDate).format('MMM DD, YYYY');
  // payment status: Paid, Upcoming, Receivable
  if (transaction.payment_status.toLowerCase() === 'paid') {
    return `Paid ${installmentPaidDateString}`;
  } else if (transaction.payment_status.toLowerCase() === 'upcoming') {
    return `Due ${dateString}`;
  } else if (transaction.payment_status.toLowerCase() === 'receivable') {
    const now = moment(new Date());
    return `${now.diff(moment.utc(date), 'days')} Days Past Due`;
  }
  return dateString;
};

export default formatPaymentDate;
