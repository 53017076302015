import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import countries from '../../../../data/countries.json';
import states from '../../../../data/states.json';

import '../../lease-abstraction.scss';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const LeaseInformation = ({ propertyOptions }) => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
    validateLeaseAbstractionFields,
  } = useContext(LeaseAbstractionContext);
  return (
    <FileUploadAccordian accordianTitle="Lease Information">
      <LeaseAbstractionField
        key="leaseStartDate"
        fieldType={LeaseAbstractionFieldType.DATE}
        fieldData={{
          data: modalFieldState.leaseStartDate,
          label: 'Lease Start Date',
          errorMessage: fieldErrorMap.leaseStartDate,
        }}
        fieldChangeHandler={(value) => {
          modalFieldChangeHandler('leaseStartDate', { value });
        }}
        fieldValidityChangeHandler={(value) => {
          modalFieldChangeHandler(
            'isLeaseStartDateValid',
            { isLeaseStartDateValid: value },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'lease_info',
            child_key: modalFieldState.leaseStartDate.trackingID,
            stateVariable: 'leaseStartDate',
          });
        }}
      />
      <LeaseAbstractionField
        key="leaseEndDate"
        fieldType={LeaseAbstractionFieldType.DATE}
        fieldData={{
          data: modalFieldState.leaseEndDate,
          label: 'Lease End Date',
          errorMessage: fieldErrorMap.leaseEndDate,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('leaseEndDate', { value: event });
        }}
        fieldValidityChangeHandler={(value) => {
          modalFieldChangeHandler(
            'isLeaseEndDateValid',
            { isLeaseEndDateValid: value },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'lease_info',
            child_key: modalFieldState.leaseEndDate.trackingID,
            stateVariable: 'leaseEndDate',
          });
        }}
      />
      <LeaseAbstractionField
        key="leaseExecutionDate"
        fieldType={LeaseAbstractionFieldType.DATE}
        fieldData={{
          data: modalFieldState.leaseExecutionDate,
          label: 'Lease Execution Date',
          errorMessage: fieldErrorMap.leaseExecutionDate,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('leaseExecutionDate', { value: event });
        }}
        fieldValidityChangeHandler={(value) => {
          modalFieldChangeHandler(
            'isLeaseExecutionDateValid',
            { isLeaseExecutionDateValid: value },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'lease_info',
            child_key: modalFieldState.leaseExecutionDate.trackingID,
            stateVariable: 'leaseExecutionDate',
          });
        }}
      />
      <LeaseAbstractionField
        key="leaseOccupancyDate"
        fieldType={LeaseAbstractionFieldType.DATE}
        fieldData={{
          data: modalFieldState.leaseOccupancyDate,
          label: 'Lease Occupancy Date',
          errorMessage: fieldErrorMap.leaseOccupancyDate,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('leaseOccupancyDate', { value: event });
        }}
        fieldValidityChangeHandler={(value) => {
          modalFieldChangeHandler(
            'isLeaseOccupancyDateValid',
            { isLeaseOccupancyDateValid: value },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'lease_info',
            child_key: modalFieldState.leaseOccupancyDate.trackingID,
            stateVariable: 'leaseOccupancyDate',
          });
        }}
      />
      <LeaseAbstractionField
        key="isASublease"
        fieldType={LeaseAbstractionFieldType.RADIO}
        fieldData={{
          data: modalFieldState.isASublease,
          label: 'Is this a sublease?',
          errorMessage: fieldErrorMap.isASublease,
          radioButtons: [
            {
              value: 'false',
              text: 'No',
            },
            {
              value: 'true',
              text: 'Yes',
            },
          ],
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('isASublease', { value: event.target.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.isASublease.trackingID,
            stateVariable: 'isASublease',
          });
        }}
      />
      <p className="file-modal-sub-heading">Property Being Leased</p>
      <LeaseAbstractionField
        key="propertyName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.propertyName,
          label: 'Property Name',
          errorMessage: fieldErrorMap.propertyName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('propertyName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.propertyName.trackingID,
            stateVariable: 'propertyName',
          });
        }}
      />
      <LeaseAbstractionField
        key="country"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.country,
          label: 'Country',
          errorMessage: fieldErrorMap.country,
          options: countries,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler(
            'multipleFieldNames',
            {
              country: { ...modalFieldState.country, value: event.value },
              stateName: { ...modalFieldState.stateName, value: '' },
              filteredStatesForProperty: {
                value: getFilteredStates(event.value),
              },
            },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.country.trackingID,
            stateVariable: 'country',
          });
        }}
      />
      <LeaseAbstractionField
        key="address"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.address,
          label: 'Address',
          errorMessage: fieldErrorMap.address,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('address', { value: event.target.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.address.trackingID,
            stateVariable: 'address',
          });
        }}
      />
      <LeaseAbstractionField
        key="city"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.city,
          label: 'City',
          errorMessage: fieldErrorMap.city,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('city', { value: event.target.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.city.trackingID,
            stateVariable: 'city',
          });
        }}
      />
      <LeaseAbstractionField
        key="stateName"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.stateName,
          label: 'State',
          errorMessage: fieldErrorMap.stateName,
          options: modalFieldState.filteredStatesForProperty.value,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('stateName', { value: event.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.stateName.trackingID,
            stateVariable: 'stateName',
          });
        }}
      />
      <LeaseAbstractionField
        key="zip"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.zip,
          label: 'Zip',
          errorMessage: fieldErrorMap.zip,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('zip', { value: event.target.value });
        }}
        validateLeaseAbstractionFields={validateLeaseAbstractionFields}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.zip.trackingID,
            stateVariable: 'zip',
          });
        }}
      />
      <LeaseAbstractionField
        key="isWholeBuilding"
        fieldType={LeaseAbstractionFieldType.RADIO}
        fieldData={{
          data: modalFieldState.isWholeBuilding,
          label: 'Is this the whole building, or suits in a building?',
          errorMessage: fieldErrorMap.isWholeBuilding,
          radioButtons: [
            {
              value: 'true',
              text: 'Whole Building',
            },
            {
              value: 'false',
              text: 'Suite(s)',
            },
          ],
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('isWholeBuilding', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.isWholeBuilding.trackingID,
            stateVariable: 'isWholeBuilding',
          });
        }}
      />
      <LeaseAbstractionField
        key="propertyType"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.propertyType,
          label: 'Property Type/Subtype',
          errorMessage: fieldErrorMap.propertyType,
          options: propertyOptions.filter(opt => opt.value !== ''),
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('propertyType', { value: event.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.propertyType.trackingID,
            stateVariable: 'propertyType',
          });
        }}
      />
      {modalFieldState.isWholeBuilding.value.toString().toLowerCase() ===
        'true' && (
        <>
          <LeaseAbstractionField
            key="squareFeet"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: modalFieldState.squareFeet,
              label: 'Square Footage',
              errorMessage: fieldErrorMap.squareFeet,
            }}
            fieldChangeHandler={(event) => {
              const newValue = event.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(newValue) || newValue === '') {
                modalFieldChangeHandler('squareFeet', { value: newValue });
              }
              // modalFieldChangeHandler('squareFeet', {
              //   value: event.target.value,
              // });
            }}
            validateLeaseAbstractionFields={validateLeaseAbstractionFields}
            individualFieldChangeHandler={(event) => {
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'lease_info',
                child_key: modalFieldState.squareFeet.trackingID,
                stateVariable: 'squareFeet',
              });
            }}
          />
        </>
      )}
      <LeaseAbstractionField
        key="parcelSize"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.parcelSize,
          label: 'Parcel Size/Acers',
          errorMessage: fieldErrorMap.parcelSize,
        }}
        fieldChangeHandler={(event) => {
          const newValue = event.target.value;
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(newValue) || newValue === '') {
            modalFieldChangeHandler('parcelSize', { value: newValue });
          }
          // modalFieldChangeHandler('parcelSize', { value: event.target.value });
        }}
        validateLeaseAbstractionFields={validateLeaseAbstractionFields}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.parcelSize.trackingID,
            stateVariable: 'parcelSize',
          });
        }}
      />
      <LeaseAbstractionField
        key="isListedByCBRE"
        fieldType={LeaseAbstractionFieldType.RADIO}
        fieldData={{
          data: modalFieldState.isListedByCBRE,
          label: 'Is the property listed by CBRE?',
          errorMessage: fieldErrorMap.isListedByCBRE,
          radioButtons: [
            {
              value: 'false',
              text: 'No',
            },
            {
              value: 'true',
              text: 'Yes',
            },
          ],
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('isListedByCBRE', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'lease_info',
            child_key: modalFieldState.isListedByCBRE.trackingID,
            stateVariable: 'isListedByCBRE',
          });
        }}
      />
      {modalFieldState.isWholeBuilding.value.toString().toLowerCase() ===
        'false' && (
        <>
          <p className="file-modal-sub-heading">Suite #1</p>
          <LeaseAbstractionField
            key="suite"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: modalFieldState.suite,
              label: 'Suite',
              errorMessage: fieldErrorMap.suite,
            }}
            fieldChangeHandler={(event) => {
              modalFieldChangeHandler('suite', { value: event.target.value });
            }}
            individualFieldChangeHandler={(event) => {
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'lease_info',
                child_key: modalFieldState.suite.trackingID,
                stateVariable: 'suite',
              });
            }}
          />
          <LeaseAbstractionField
            key="floorChange"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: modalFieldState.floorChange,
              label: 'Floor',
              errorMessage: fieldErrorMap.floorChange,
            }}
            fieldChangeHandler={(event) => {
              modalFieldChangeHandler('floorChange', {
                value: event.target.value,
              });
            }}
            individualFieldChangeHandler={(event) => {
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'lease_info',
                child_key: modalFieldState.floorChange.trackingID,
                stateVariable: 'floorChange',
              });
            }}
          />
          <LeaseAbstractionField
            key="squareFeet"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: modalFieldState.squareFeet,
              label: 'Square Feet',
              errorMessage: fieldErrorMap.squareFeet,
            }}
            fieldChangeHandler={(event) => {
              const newValue = event.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(newValue) || newValue === '') {
                modalFieldChangeHandler('squareFeet', { value: newValue });
              }
              // modalFieldChangeHandler('squareFeet', {
              //   value: event.target.value,
              // });
            }}
            validateLeaseAbstractionFields={validateLeaseAbstractionFields}
            individualFieldChangeHandler={(event) => {
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'lease_info',
                child_key: modalFieldState.squareFeet.trackingID,
                stateVariable: 'squareFeet',
              });
            }}
          />
        </>
      )}
    </FileUploadAccordian>
  );
};

LeaseInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  })),
};

LeaseInformation.defaultProps = {
  propertyOptions: [{ display_name: 'Select', value: null }],
};

export default LeaseInformation;
