import React from 'react';
import PropTypes from 'prop-types';

import LeaseInformation from './sections/lease-information';
import TenantInformation from './sections/tenant-information';
import LandlordInformation from './sections/landlord-information';

const LeaseDetails = ({
  propertyOptions,
}) => (
  <>
    <LeaseInformation
      propertyOptions={propertyOptions}
    />
    <TenantInformation />
    <LandlordInformation />
  </>
);

LeaseDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  })),
};

LeaseDetails.defaultProps = {
  propertyOptions: [
    { display_name: 'Select', value: null },
  ],
};

export default LeaseDetails;
