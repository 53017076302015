import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';

import { IconButton } from '@cbrebuild/blocks';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../../leaseAbstractionField';

import '../../../lease-abstraction.scss';
import LeaseAbstractionContext from '../../../contexts/leaseAbstractionContext';

const considerationTypeOptions = [
  { display_name: 'Annual Rent', value: 'Annual Rent' },
  { display_name: 'Monthly Rent', value: 'Monthly Rent' },
  { display_name: 'Free Monthly Rent', value: 'Free Monthly Rent' },
  { display_name: 'Free Annual Rent', value: 'Free Annual Rent' },
];

const CustomConsiderationField = ({ currentCustomConsideration }) => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
  } = useContext(LeaseAbstractionContext);
  const updateConsiderationAndCommission = (newCustomConsiderationValue) => {
    const updatedValue = { ...newCustomConsiderationValue };
    const {
      amount, total_units, commission_percent, commission_dollars,
    } =
      updatedValue;
    const newConsideration = round(
      parseFloat(amount || 0) * parseFloat(total_units || 0),
      2,
    );
    updatedValue.consideration_amount = newConsideration.toString();

    if (modalFieldState.considerationIsPercent.value.toLowerCase() === 'true') {
      updatedValue.commission_amount = round(
        (parseFloat(commission_percent || 0) * newConsideration) / 100,
        2,
      );
      updatedValue.commission_dollars = round(
        newConsideration * (commission_percent / 100),
        2,
      );
    } else {
      updatedValue.commission_amount = round(commission_dollars, 2);
      updatedValue.commission_percent = round(
        (commission_dollars / newConsideration) * 100,
        8,
      );
    }
    return updatedValue;
  };

  const handleTypeChange = (event, newCustomConsiderationValue) => {
    const updatedValue = { ...newCustomConsiderationValue };
    updatedValue.consideration_type = event.value;
    if (['Free Monthly Rent', 'Free Annual Rent'].includes(event.value)) {
      updatedValue.amount = 0;
      updatedValue.consideration_amount = 0;
      updatedValue.commission_amount = 0;
      updatedValue.commission_dollars = 0;
      updatedValue.commission_percent = 0;
    }
    return updatedValue;
  };

  const attPropsChildValue = (newCustomConsiderationValue) => {
    const child_value = newCustomConsiderationValue.map(item => ({
      consideration_type: String(item.consideration_type),
      amount: String(item.amount),
      total_units: String(item.total_units),
      consideration_amount: String(item.consideration_amount),
      commission_amount: String(item.commission_amount),
      commission_percent: String(item.commission_percent),
    }));
    return child_value;
  };

  const formatCurrency = value =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

  const handlePeriodOrRentChange = (
    event,
    field,
    newCustomConsiderationValue,
  ) => {
    const updatedValue = { ...newCustomConsiderationValue };
    updatedValue[field] = event.target.value;
    if (
      !['Free Monthly Rent', 'Free Annual Rent'].includes(updatedValue.consideration_type)
    ) {
      return updateConsiderationAndCommission(updatedValue);
    }
    return updatedValue;
  };

  const handleCommissionRateChange = (event, newCustomConsiderationValue) => {
    const updatedValue = { ...newCustomConsiderationValue };
    updatedValue.commission_dollars =
      round(event.value.commission_dollars, 2) || '';
    updatedValue.commission_percent =
      round(event.value.commission_percent, 8) || '';
    if (
      !['Free Monthly Rent', 'Free Annual Rent'].includes(updatedValue.consideration_type)
    ) {
      if (
        modalFieldState.considerationIsPercent.value.toLowerCase() === 'true'
      ) {
        updatedValue.commission_amount = round(
          (parseFloat(event.value.commission_percent || 0) *
            parseFloat(updatedValue.consideration_amount || 0)) /
            100,
          2,
        );
      } else {
        updatedValue.commission_amount = round(
          event.value.commission_dollars,
          2,
        );
      }
    }
    return updatedValue;
  };

  const handleCustomFieldChange = (event, field) => {
    const currentValue = [...modalFieldState.customConsideration.value];
    let newCustomConsiderationValue = { ...currentCustomConsideration };

    switch (field) {
      case 'consideration_type':
        newCustomConsiderationValue = handleTypeChange(
          event,
          newCustomConsiderationValue,
        );
        break;
      case 'total_units':
      case 'amount':
        newCustomConsiderationValue = handlePeriodOrRentChange(
          event,
          field,
          newCustomConsiderationValue,
        );
        break;
      case 'consideration_amount':
        newCustomConsiderationValue.consideration_amount = event.target.value;
        break;
      case 'commission_amount':
        newCustomConsiderationValue.commission_amount = event.target.value;
        break;
      case 'commission_is_percent':
        break;
      case 'commission_percent':
        newCustomConsiderationValue = handleCommissionRateChange(
          event,
          newCustomConsiderationValue,
        );
        break;
      default:
        break;
    }

    currentValue.splice(
      currentValue.findIndex(rent => rent.id === newCustomConsiderationValue.id),
      1,
      newCustomConsiderationValue,
    );
    modalFieldChangeHandler('customConsideration', { value: currentValue });
    if (field === 'consideration_type') {
      individualModalFieldChangeHandler({
        child_value: attPropsChildValue(currentValue),
        parent_key: 'consideration_info',
        child_key: modalFieldState.customConsideration.trackingID,
        stateVariable: 'customConsideration',
      });
    }
  };

  const addDecimals = (field) => {
    const newCustomConsiderationValue = [
      ...modalFieldState.customConsideration.value,
    ];
    const updatedValue = { ...currentCustomConsideration };
    updatedValue[field] = parseFloat(updatedValue[field]).toFixed(2);
    newCustomConsiderationValue.splice(
      newCustomConsiderationValue.findIndex(item => item.id === updatedValue.id),
      1,
      updatedValue,
    );
    modalFieldChangeHandler('customConsideration', {
      value: newCustomConsiderationValue,
    });
    individualModalFieldChangeHandler({
      child_value: attPropsChildValue(newCustomConsiderationValue),
      parent_key: 'consideration_info',
      child_key: modalFieldState.customConsideration.trackingID,
      stateVariable: 'customConsideration',
    });
  };

  const handleIndividualFieldChange = () => {
    const newCustomConsiderationValue = [
      ...modalFieldState.customConsideration.value,
    ];
    individualModalFieldChangeHandler({
      child_value: attPropsChildValue(newCustomConsiderationValue),
      parent_key: 'consideration_info',
      child_key: modalFieldState.customConsideration.trackingID,
      stateVariable: 'customConsideration',
    });
  };

  return (
    <div className="file-modal-flex-group">
      <div className="file-modal-custom-field">
        <div className="file-modal-flex-group">
          <LeaseAbstractionField
            asGroup
            key="considerationType"
            fieldType={LeaseAbstractionFieldType.SELECT}
            fieldData={{
              data: {
                value: currentCustomConsideration.consideration_type,
              },
              label: 'Consideration Type',
              errorMessage: fieldErrorMap.customConsideration,
              options: considerationTypeOptions,
            }}
            fieldChangeHandler={(event) => {
              handleCustomFieldChange(event, 'consideration_type');
            }}
          />
          <LeaseAbstractionField
            asGroup
            key="considerationPeriod"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: {
                value: currentCustomConsideration.total_units,
              },
              label: 'Period',
              errorMessage: fieldErrorMap.customConsideration,
            }}
            fieldChangeHandler={(event) => {
              const newValue = event.target.value;
              const regex = /^\d+(\.\d{0,4})?$/;
              if (regex.test(newValue) || newValue === '') {
                handleCustomFieldChange(event, 'total_units');
              }
            }}
            individualFieldChangeHandler={() => {
              handleIndividualFieldChange();
            }}
          />
          <LeaseAbstractionField
            asGroup
            disabled={['Free Monthly Rent', 'Free Annual Rent'].includes(currentCustomConsideration.consideration_type)}
            key="considerationRent"
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data: {
                value: currentCustomConsideration.amount,
              },
              label: 'Rent ($)',
              errorMessage: fieldErrorMap.considerationRent,
            }}
            fieldChangeHandler={(event) => {
              const newValue = event.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(newValue) || newValue === '') {
                handleCustomFieldChange(event, 'amount');
              }
            }}
            onBlur={() => {
              addDecimals('amount');
            }}
            individualFieldChangeHandler={() => {
              handleIndividualFieldChange();
            }}
          />
        </div>
        <div className="file-modal-flex-group">
          <LeaseAbstractionField
            asGroup
            disabled
            key="consideration_amount"
            fieldType={LeaseAbstractionFieldType.READ_ONLY}
            fieldData={{
              data: {
                value: formatCurrency(currentCustomConsideration.consideration_amount),
              },
              label: 'Consideration',
              errorMessage: fieldErrorMap.consideration_amount,
            }}
            fieldChangeHandler={(event) => {
              handleCustomFieldChange(event, 'consideration_amount');
            }}
            individualFieldChangeHandler={() => {
              handleIndividualFieldChange();
            }}
          />
          <LeaseAbstractionField
            customPercentDollarField={{
              percentage: 'commission_percent',
              value: 'commission_dollars',
              select: 'considerations_is_percent',
            }}
            percentFieldCustomOnTypeChange={(event) => {
              modalFieldChangeHandler('considerationIsPercent', {
                value: event.target.value ? 'True' : 'False',
              });
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'consideration_info',
                child_key: modalFieldState.considerationIsPercent.trackingID,
                stateVariable: 'considerationIsPercent',
              });
            }}
            percentFieldBaseTotal={parseFloat(currentCustomConsideration.consideration_amount.toString()
                .length > 0
                ? currentCustomConsideration.consideration_amount
                : 0)}
            percentageFieldCustomPercentage={parseFloat(currentCustomConsideration.commission_percent.toString().length >
                0
                ? currentCustomConsideration.commission_percent
                : 0)}
            percentageFieldCustomValue={parseFloat(currentCustomConsideration.commission_dollars.toString().length >
                0
                ? currentCustomConsideration.commission_dollars
                : 0)}
            percentFieldCustomIsPercent={
              modalFieldState.considerationIsPercent.value.toLowerCase() ===
              'true'
            }
            asGroup
            disabled={['Free Monthly Rent', 'Free Annual Rent'].includes(currentCustomConsideration.consideration_type)}
            key="commissionRate"
            fieldType={LeaseAbstractionFieldType.PERCENT_DOLLAR}
            fieldData={{
              data: {
                value: currentCustomConsideration.commissionRate,
              },
              label: 'Commission Rate',
              errorMessage: fieldErrorMap.commissionRate,
            }}
            fieldChangeHandler={(event) => {
              handleCustomFieldChange(event, 'commission_percent');
            }}
            individualFieldChangeHandler={() => {
              handleIndividualFieldChange();
            }}
          />
          <LeaseAbstractionField
            asGroup
            disabled
            key="commission_amount"
            fieldType={LeaseAbstractionFieldType.READ_ONLY}
            fieldData={{
              data: {
                value: formatCurrency(currentCustomConsideration.commission_amount),
              },
              label: 'Commission',
              errorMessage: fieldErrorMap.commission_amount,
            }}
            fieldChangeHandler={(event) => {
              const newValue = event.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(newValue) || newValue === '') {
                handleCustomFieldChange(event, 'commission_amount');
              }
            }}
            individualFieldChangeHandler={() => {
              handleIndividualFieldChange();
            }}
          />
        </div>
      </div>
      <IconButton
        className="blxs-button-icon-small"
        iconName="close-circle"
        onClick={() => {
          const newCustomConsiderationValue = [
            ...modalFieldState.customConsideration.value,
          ];
          newCustomConsiderationValue.splice(
            newCustomConsiderationValue.findIndex(item => item.id === currentCustomConsideration.id),
            1,
          );
          modalFieldChangeHandler('customConsideration', {
            value: newCustomConsiderationValue,
          });
          individualModalFieldChangeHandler({
            child_value: attPropsChildValue(newCustomConsiderationValue),
            parent_key: 'consideration_info',
            child_key: modalFieldState.customConsideration.trackingID,
            stateVariable: 'customConsideration',
          });
        }}
        variant="basic"
      />
    </div>
  );
};

CustomConsiderationField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentCustomConsideration: PropTypes.object,
};

CustomConsiderationField.defaultProps = {
  currentCustomConsideration: {},
};

export default CustomConsiderationField;
