import React, { useContext } from 'react';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';
import AddItem from '../../../../nucleus/add-item/add-item';

import '../../lease-abstraction.scss';
import CustomLeaseEscalationField from './components/customLeaseEscalationField';
import { getRandomId } from '../../../../utils/validation-utils';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const leaseEscalationOptions = [
  { display_name: 'Fixed', value: 'Fixed' },
  { display_name: 'Custom', value: 'Custom' },
];

const RentAndEscalations = () => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
  } = useContext(LeaseAbstractionContext);
  const convertToYearsAndMonths = (months) => {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return { years, months: remainingMonths };
  };
  return (
    <FileUploadAccordian accordianTitle="Rent & Escalation">
      <LeaseAbstractionField
        key="baseRent"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.baseRent,
          label: 'Base Rent($/SqFt per year)',
          errorMessage: fieldErrorMap.baseRent,
        }}
        fieldChangeHandler={(event) => {
          const newValue = event.target.value;
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(newValue) || newValue === '') {
            modalFieldChangeHandler('baseRent', { value: newValue });
          }
          // modalFieldChangeHandler('baseRent', {
          //   value: event.target.value,
          // });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'comp_info',
            child_key: modalFieldState.baseRent.trackingID,
            stateVariable: 'baseRent',
          });
        }}
      />
      <div className="file-modal-flex-group">
        <LeaseAbstractionField
          asGroup
          inputText="Years"
          key="freeRentYears"
          fieldType={LeaseAbstractionFieldType.TEXT_WITH_LABEL}
          fieldData={{
            data: {
              ...modalFieldState.freeRent,
              value: convertToYearsAndMonths(modalFieldState.freeRent.value)
                .years,
            },
            label: 'Free Rent',
            errorMessage: fieldErrorMap.freeRent,
          }}
          fieldChangeHandler={(event) => {
            const newValue = event.target.value;
            const regex = /^\d+(\.\d{0,2})?$/;
            if (regex.test(newValue) || newValue === '') {
              const { months } = convertToYearsAndMonths(modalFieldState.freeRent.value);
              modalFieldChangeHandler('freeRent', {
                value:
                  (parseInt(newValue.length === 0 ? 0 : newValue, 10) * 12) +
                  months,
              });
            }
          }}
          individualFieldChangeHandler={(event) => {
            const newValue = event.target.value;
            const regex = /^\d+(\.\d{0,2})?$/;
            if (regex.test(newValue) || newValue === '') {
              const { months } = convertToYearsAndMonths(modalFieldState.freeRent.value);
              individualModalFieldChangeHandler({
                child_value:
                  (parseInt(newValue.length === 0 ? 0 : newValue, 10) * 12) +
                  months,
                parent_key: 'comp_info',
                child_key: modalFieldState.freeRent.trackingID,
                stateVariable: 'freeRent',
              });
            }
          }}
        />
        <LeaseAbstractionField
          asGroup
          showToolTip={false}
          inputText="Months"
          key="freeRentMonths"
          fieldType={LeaseAbstractionFieldType.TEXT}
          fieldData={{
            data: {
              ...modalFieldState.freeRent,
              value: convertToYearsAndMonths(modalFieldState.freeRent.value)
                .months,
            },
            errorMessage: fieldErrorMap.freeRent,
          }}
          fieldChangeHandler={(event) => {
            const newValue = event.target.value;
            const regex = /^\d+(\.\d{0,2})?$/;
            if (regex.test(newValue) || newValue === '') {
              const { years } = convertToYearsAndMonths(modalFieldState.freeRent.value);
              modalFieldChangeHandler('freeRent', {
                value:
                  (years * 12) +
                  parseInt(newValue.length === 0 ? 0 : newValue, 10),
              });
            }
            // modalFieldChangeHandler('freeRent', {
            //   value: event.target.value,
            // });
          }}
          individualFieldChangeHandler={(event) => {
            const newValue = event.target.value;
            const regex = /^\d+(\.\d{0,2})?$/;
            if (regex.test(newValue) || newValue === '') {
              const { years } = convertToYearsAndMonths(modalFieldState.freeRent.value);
              individualModalFieldChangeHandler({
                child_value:
                  (years * 12) +
                  parseInt(newValue.length === 0 ? 0 : newValue, 10),
                parent_key: 'comp_info',
                child_key: modalFieldState.freeRent.trackingID,
                stateVariable: 'freeRent',
              });
            }
          }}
        />
      </div>
      <LeaseAbstractionField
        key="leaseEscalation"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.leaseEscalation,
          label: 'Lease Escalation',
          errorMessage: fieldErrorMap.leaseEscalation,
          options: leaseEscalationOptions,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('leaseEscalation', {
            value: event.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          const child_value = event.value !== 'Fixed' ? 'true' : 'false';
          individualModalFieldChangeHandler({
            child_value,
            parent_key: 'comp_info',
            child_key: modalFieldState.leaseEscalation.trackingID,
            stateVariable: 'leaseEscalation',
          });
        }}
      />
      {modalFieldState.leaseEscalation.value === 'Fixed' && (
        <LeaseAbstractionField
          asGroup
          percentDollarHideConversion
          customPercentDollarField={{
            select: 'rent_escalation_is_percent',
            value: 'rent_escalation_dollar',
            percentage: 'rent_escalation_percent',
          }}
          key="escalationPerYear"
          fieldType={LeaseAbstractionFieldType.PERCENT_DOLLAR}
          fieldData={{
            data: modalFieldState.fixedLeaseEscalation,
            label: 'Escalation (per year)',
            errorMessage: fieldErrorMap.fixedLeaseEscalation,
          }}
          fieldChangeHandler={(event) => {
            modalFieldChangeHandler('fixedLeaseEscalation', {
              value: event.value,
            });
            individualModalFieldChangeHandler({
              child_value: String(event.value.rent_escalation_is_percent),
              parent_key: 'comp_info',
              child_key: 'rent_escalation_is_percent',
              stateVariable: 'fixedLeaseEscalation',
            });
          }}
          individualFieldChangeHandler={(event) => {
            individualModalFieldChangeHandler({
              child_value:
                event.target.value.rent_escalation_dollar !== null
                  ? event.target.value.rent_escalation_dollar
                  : 0.0,
              parent_key: 'comp_info',
              child_key: 'rent_escalation_dollar',
              stateVariable: 'fixedLeaseEscalation',
            });
            individualModalFieldChangeHandler({
              child_value:
                event.target.value.rent_escalation_percent !== null
                  ? event.target.value.rent_escalation_percent
                  : 0.0,
              parent_key: 'comp_info',
              child_key: 'rent_escalation_percent',
              stateVariable: 'fixedLeaseEscalation',
            });
          }}
        />
      )}
      {/* <div className="file-modal-flex-group">
        {modalFieldState.leaseEscalation.value === 'Fixed' && (
          <LeaseAbstractionField
            asGroup
            key="rentEscalationIsPercent"
            fieldType={LeaseAbstractionFieldType.SELECT}
            fieldData={{
              data: modalFieldState.rentEscalationIsPercent,
              label: 'Escalation (per year)',
              errorMessage: fieldErrorMap.rentEscalationIsPercent,
              options: rentEscalationIsPercentOptions,
            }}
            fieldChangeHandler={(event) => {
              modalFieldChangeHandler('rentEscalationIsPercent', {
                value: event.value,
              });
            }}
            individualFieldChangeHandler={(event) => {
              const child_value = event.value === '%' ? 'true' : 'false';
              individualModalFieldChangeHandler({
                child_value,
                parent_key: 'comp_info',
                child_key: modalFieldState.rentEscalationIsPercent.trackingID,
                stateVariable: 'rentEscalationIsPercent',
              });
            }}
          />
        )}
        {modalFieldState.leaseEscalation.value === 'Fixed' && (
          <LeaseAbstractionField
            asGroup
            inputText={
              modalFieldState.rentEscalationIsPercent.value === '%' ? '%' : '$'
            }
            key={
              modalFieldState.rentEscalationIsPercent.value === '%'
                ? 'rentEscalationPercentage'
                : 'rentEscalationDollar'
            }
            fieldType={LeaseAbstractionFieldType.TEXT}
            fieldData={{
              data:
                modalFieldState.rentEscalationIsPercent.value === '%'
                  ? modalFieldState.rentEscalationPercent
                  : modalFieldState.rentEscalationDollar,
              errorMessage: fieldErrorMap.rentEscalationIsPercent,
            }}
            fieldChangeHandler={(event) => {
              if (modalFieldState.rentEscalationIsPercent.value === '%') {
                modalFieldChangeHandler('rentEscalationPercent', {
                  value: event.target.value,
                });
              } else {
                modalFieldChangeHandler('rentEscalationDollar', {
                  value: event.target.value,
                });
              }
            }}
            individualFieldChangeHandler={(event) => {
              individualModalFieldChangeHandler({
                child_value: event.target.value,
                parent_key: 'comp_info',
                child_key:
                  modalFieldState.rentEscalationIsPercent.value === '%'
                    ? modalFieldState.rentEscalationPercent.trackingID
                    : modalFieldState.rentEscalationDollar.trackingID,
                stateVariable:
                  modalFieldState.rentEscalationIsPercent.value === '%'
                    ? 'rentEscalationPercent'
                    : 'rentEscalationDollar',
              });
            }}
          />
        )}
      </div> */}
      {modalFieldState.leaseEscalation.value === 'Custom' && (
        <>
          {modalFieldState.customLeaseEscalation.value.map(customLeaseEscalation => (
            <CustomLeaseEscalationField
              modalFieldState={modalFieldState}
              modalFieldChangeHandler={modalFieldChangeHandler}
              individualModalFieldChangeHandler={
                  individualModalFieldChangeHandler
                }
              fieldErrorMap={fieldErrorMap}
              currentCustomLeaseEscalation={customLeaseEscalation}
              key={customLeaseEscalation.id}
            />
            ))}
          <div className="file-modal-add-button">
            <AddItem
              label="Add Rent"
              onClick={() => {
                const newCustomLeaseEscalationValue = [
                  ...modalFieldState.customLeaseEscalation.value,
                ];
                newCustomLeaseEscalationValue.push({
                  id: getRandomId(),
                  rent: {
                    is_percent: false,
                    rent_dollar: 0,
                    rent_percent: 0,
                  },
                  months: '',
                });
                modalFieldChangeHandler('customLeaseEscalation', {
                  value: newCustomLeaseEscalationValue,
                });
                const child_value = newCustomLeaseEscalationValue.map(item => ({
                    months: String(item.months),
                    rent_dollar: String(item.rent.rent_dollar),
                    rent_percent: String(item.rent.rent_percent),
                    is_percent: String(item.rent.is_percent),
                  }));
                individualModalFieldChangeHandler({
                  child_value,
                  parent_key: 'comp_info',
                  child_key: modalFieldState.customLeaseEscalation.trackingID,
                  stateVariable: 'customLeaseEscalation',
                });
              }}
            />
          </div>
        </>
      )}
    </FileUploadAccordian>
  );
};

RentAndEscalations.propTypes = {};

RentAndEscalations.defaultProps = {};

export default RentAndEscalations;
