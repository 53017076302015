import React from 'react';
import GeneralInformation from './sections/general-information';
import RentAndEscalations from './sections/rent-and-escalations';
import Expenses from './sections/expenses';

const LeaseComp = () => (
  <>
    <GeneralInformation />
    <RentAndEscalations />
    <Expenses />
  </>
);

LeaseComp.propTypes = {};

LeaseComp.defaultProps = {};

export default LeaseComp;
