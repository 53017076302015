import React from 'react';
import Considerations from './sections/considerations';

const Financials = () => (
  <>
    <Considerations />
  </>
);

Financials.propTypes = {};

Financials.defaultProps = {};

export default Financials;
