import React, { useContext } from 'react';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const GeneralInformation = () => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
  } = useContext(LeaseAbstractionContext);
  return (
    <FileUploadAccordian accordianTitle="General Information">
      <LeaseAbstractionField
        key="tenantRepresentCompany"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantRepresentCompany,
          label: 'Tenant Represented By',
          errorMessage: fieldErrorMap.tenantRepresentCompany,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantRepresentCompany', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'comp_info',
            child_key: modalFieldState.tenantRepresentCompany.trackingID,
            stateVariable: 'tenantRepresentCompany',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantRepresentAgent"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantRepresentAgent,
          label: 'Tenant Represented By Agent',
          errorMessage: fieldErrorMap.tenantRepresentAgent,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantRepresentAgent', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'comp_info',
            child_key: modalFieldState.tenantRepresentAgent.trackingID,
            stateVariable: 'tenantRepresentAgent',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordRepresentCompany"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordRepresentCompany,
          label: 'Landlord Represented By',
          errorMessage: fieldErrorMap.landlordRepresentCompany,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordRepresentCompany', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'comp_info',
            child_key: modalFieldState.landlordRepresentCompany.trackingID,
            stateVariable: 'landlordRepresentCompany',
          });
        }}
      />
      <LeaseAbstractionField
        key="landlordRepresentAgent"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.landlordRepresentAgent,
          label: 'Landlord Represented By Agent',
          errorMessage: fieldErrorMap.landlordRepresentAgent,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('landlordRepresentAgent', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'comp_info',
            child_key: modalFieldState.landlordRepresentAgent.trackingID,
            stateVariable: 'landlordRepresentAgent',
          });
        }}
      />
    </FileUploadAccordian>
  );
};

GeneralInformation.propTypes = {
};

GeneralInformation.defaultProps = {
};

export default GeneralInformation;
