import React, { useContext } from 'react';
import FileUploadAccordian from '../../file-upload-accordian';
import countries from '../../../../data/countries.json';
import states from '../../../../data/states.json';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';
import LeaseAbstractionContext from '../../contexts/leaseAbstractionContext';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const TenantInformation = () => {
  const {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
  } = useContext(LeaseAbstractionContext);
  return (
    <FileUploadAccordian accordianTitle="Tenant Information">
      <LeaseAbstractionField
        key="tenantCompanyName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantCompanyName,
          label: 'Company Name',
          errorMessage: fieldErrorMap.tenantCompanyName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantCompanyName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantCompanyName.trackingID,
            stateVariable: 'tenantCompanyName',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantCountryName"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.tenantCountryName,
          label: 'Country',
          errorMessage: fieldErrorMap.tenantCountryName,
          options: countries,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler(
            'multipleFieldNames',
            {
              tenantCountryName: {
                ...modalFieldState.tenantCountryName,
                value: event.value,
              },
              tenantStateName: {
                ...modalFieldState.tenantStateName,
                value: '',
              },
              filteredStatesForTenant: {
                value: getFilteredStates(event.value),
              },
            },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantCountryName.trackingID,
            stateVariable: 'tenantCountryName',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantAddressLine1"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantAddressLine1,
          label: 'Address Line 1',
          errorMessage: fieldErrorMap.tenantAddressLine1,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantAddressLine1', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantAddressLine1.trackingID,
            stateVariable: 'tenantAddressLine1',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantAddressLine2"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantAddressLine2,
          label: 'Address Line 2',
          errorMessage: fieldErrorMap.tenantAddressLine2,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantAddressLine2', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantAddressLine2.trackingID,
            stateVariable: 'tenantAddressLine2',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantCity"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantCity,
          label: 'City',
          errorMessage: fieldErrorMap.tenantCity,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantCity', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantCity.trackingID,
            stateVariable: 'tenantCity',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantStateName"
        fieldType={LeaseAbstractionFieldType.SELECT}
        fieldData={{
          data: modalFieldState.tenantStateName,
          label: 'State',
          errorMessage: fieldErrorMap.tenantStateName,
          options: modalFieldState.filteredStatesForTenant.value,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantStateName', { value: event.value });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantStateName.trackingID,
            stateVariable: 'tenantStateName',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantZip"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantZip,
          label: 'Zip',
          errorMessage: fieldErrorMap.tenantZip,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantZip', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantZip.trackingID,
            stateVariable: 'tenantZip',
          });
        }}
      />
      <p className="file-modal-sub-heading">Point of Contact</p>
      <LeaseAbstractionField
        key="tenantPOCFirstName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantPOCFirstName,
          label: 'First Name',
          errorMessage: fieldErrorMap.tenantPOCFirstName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantPOCFirstName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantPOCFirstName.trackingID,
            stateVariable: 'tenantPOCFirstName',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantPOCLastName"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantPOCLastName,
          label: 'Last Name',
          errorMessage: fieldErrorMap.tenantPOCLastName,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantPOCLastName', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantPOCLastName.trackingID,
            stateVariable: 'tenantPOCLastName',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantPOCPhone"
        fieldType={LeaseAbstractionFieldType.PHONE}
        fieldData={{
          data: modalFieldState.tenantPOCPhone,
          label: 'Phone',
          errorMessage: fieldErrorMap.tenantPOCPhone,
        }}
        fieldChangeHandler={(value, isValid) => {
          modalFieldChangeHandler(
            'multipleFieldNames',
            {
              tenantPOCPhone: {
                ...modalFieldState.tenantPOCPhone,
                value,
              },
              tenantPOCPhoneValid: isValid,
            },
            'multiple',
          );
        }}
        individualFieldChangeHandler={(value) => {
          individualModalFieldChangeHandler({
            child_value: value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantPOCPhone.trackingID,
            stateVariable: 'tenantPOCPhone',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantPOCEmail"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantPOCEmail,
          label: 'E-Mail Address',
          errorMessage: fieldErrorMap.tenantPOCEmail,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantPOCEmail', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantPOCEmail.trackingID,
            stateVariable: 'tenantPOCEmail',
          });
        }}
      />
      <LeaseAbstractionField
        key="tenantPOCJobTitle"
        fieldType={LeaseAbstractionFieldType.TEXT}
        fieldData={{
          data: modalFieldState.tenantPOCJobTitle,
          label: 'Job Title',
          errorMessage: fieldErrorMap.tenantPOCJobTitle,
        }}
        fieldChangeHandler={(event) => {
          modalFieldChangeHandler('tenantPOCJobTitle', {
            value: event.target.value,
          });
        }}
        individualFieldChangeHandler={(event) => {
          individualModalFieldChangeHandler({
            child_value: event.target.value,
            parent_key: 'tenant_buyer_info',
            child_key: modalFieldState.tenantPOCJobTitle.trackingID,
            stateVariable: 'tenantPOCJobTitle',
          });
        }}
      />
    </FileUploadAccordian>
  );
};

TenantInformation.propTypes = {};

TenantInformation.defaultProps = {};

export default TenantInformation;
